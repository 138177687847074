
export default [
    "rgba(255, 204, 204, 0.8)",
    "rgba(255, 230, 204, 0.8)",
    "rgba(255, 255, 204, 0.8)",
    "rgba(230, 255, 204, 0.8)",
    "rgba(204, 255, 204, 0.8)",
    "rgba(204, 255, 230, 0.8)",
    "rgba(204, 255, 255, 0.8)",
    "rgba(204, 230, 255, 0.8)",
    "rgba(255, 204, 255, 0.8)",
    "rgba(230, 204, 255, 0.8)",
    "rgba(255, 204, 255, 0.8)",
    "rgba(255, 204, 230, 0.8)"
];
